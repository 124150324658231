exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-fuel-quality-js": () => import("./../../../src/pages/fuel-quality.js" /* webpackChunkName: "component---src-pages-fuel-quality-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-vessel-js": () => import("./../../../src/pages/our-vessel.js" /* webpackChunkName: "component---src-pages-our-vessel-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-the-uet-marine-difference-js": () => import("./../../../src/pages/the-uet-marine-difference.js" /* webpackChunkName: "component---src-pages-the-uet-marine-difference-js" */)
}

